<template>
  <div>
    <v-progress-linear :active="isFetching" :indeterminate="true" top color="deep-purple accent-4"> </v-progress-linear>
    <v-container class="d-flex justify-start ma-0" style="align-items: baseline; gap: 10px; flex-wrap: wrap">
      <!--      <v-row>-->
      <!--        <v-col class="" v>-->
      <v-card v-for="(report, index) in reports" :key="index" style="width: 350px">
        <div class="d-flex">
          <div class="d-flex align-center ml-4">
            <i class="mdi mdi-chart-line headline" />
          </div>
          <div>
            <v-card-text>
              <p class="text--primary subtitle-1 font-weight-bold">
                {{ report.name }}
              </p>
              <div class="font-weight-light">
                {{ report.description }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="accent-4" :to="{ path: getReportUrl(report.slug) }" v-text="$t('rafaleApp.reportGroup.report')">txt</v-btn>
            </v-card-actions>
          </div>
        </div>
      </v-card>
      <!--        </v-col>-->
      <!--      </v-row>-->
    </v-container>
  </div>
</template>
<script lang="ts" src="./report-category-slug.component.ts"></script>
<style scoped>
/* Disable flexmonster branding bar */
#fm-branding-bar {
  display: none !important;
}

.v-navigation-drawer--temporary {
  z-index: 101;
}
</style>
