<template>
  <v-container fluid>
    <v-skeleton-loader
      ref="skeleton"
      :boilerplate="skeletonLoader.tile"
      :loading="isFetching"
      :type="skeletonLoader.flexmonster"
      :tile="skeletonLoader.tile"
      class="mx-auto"
    >
      <v-card>
        <v-card-title v-text="`${report?.name ?? ''}`" />
        <v-card-text>
          <div v-if="flexmonsterKey">
            <div id="pivotGridSelector" :style="`width: 100%; height: ${flexmonsterPivotTableHeight}px;`">
              <Pivot
                ref="pivot"
                toolbar
                height="100%"
                v-bind:report="flexmonsterReport"
                v-bind:beforetoolbarcreated="customizeToolbar"
                v-bind:ready="signOnAllEvents"
                v-bind:licenseKey="flexmonsterKey"
                v-bind:localitazion="currentLanguage"
                v-bind:shareReportConnection="{
                  url: 'https://olap.flexmonster.com:9500',
                }"
                componentFolder="https://cdn.flexmonster.com/"
              ></Pivot>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <!-- Render in drawer -->
      <portal to="rightDrawer" v-if="isParameterDrawerActive">
        <parameter-group v-if="parametersReady && authorized" :parameters="parameters" @refresh-query="refreshQuery" />
      </portal>
    </v-skeleton-loader>
  </v-container>
</template>
<script lang="ts" src="./flexmonster-pivot-table.component.ts"></script>
<style scoped>
/* Disable flexmonster branding bar */
#fm-branding-bar {
  display: none !important;
}

.v-navigation-drawer--temporary {
  z-index: 101;
}
</style>
