<template>
  <v-sheet class="my-sheet" style="background-image: url('/content/images/background_carousel.jpg')" height="100%">
    <v-row class="flex rounded-card justify-center h-full items-center">
      <div class="w-1/3 text-white">
        <div class="text-center text-4xl">{{ slide.name }}</div>
        <div class="flex justify-between w-full">
          <div class="flex align-baseline">
            <div class="text-6xl font-bold">{{ slide.data.netPriceExcludeTax }}</div>
            <div class="text-2xl ml-2">{{ slide.data.currencyCode }}</div>
          </div>
          <div class="flex align-baseline">
            <div class="text-6xl font-bold">{{ slide.data.number }}</div>
            <div class="text-2xl ml-2">QT</div>
          </div>
        </div>
      </div>
    </v-row>

    <portal v-if="!disabled" to="carouselBottomComponent">
      <sales-invoices-dashboard :formatter="formatter" :disabled="disabled" :query-parameters="queryParameters" />
    </portal>

    <!-- Render in drawer -->
    <portal to="rightDrawer" v-if="isParameterDrawerActive && !disabled">
      <parameter-group v-if="parametersReady && authorized" :parameters="parameters" @refresh-query="refreshQuery"> </parameter-group>
    </portal>
  </v-sheet>
</template>
<script lang="ts" src="./sales-invoices-carousel-sheet.component.ts"></script>
<style scoped>
.my-sheet {
  background-size: cover;
  background-position: center;
}

/* tailwindcss@1.2.0 */
.w-1\/3 {
  width: 33.333333%;
}
.text-white {
  color: #ffffff;
  text-shadow: 2px 2px #000000;
}
.text-2xl {
  font-size: 1.5rem;
}
.text-4xl {
  font-size: 2.25rem;
}
.text-6xl {
  font-size: 4rem;
}
.font-bold {
  font-weight: 700;
}
*,
::after,
::before {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}
.h-full {
  height: 100%;
}
.items-center {
  align-items: center;
}
.flex {
  display: flex;
}
</style>
