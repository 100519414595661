<template>
  <div id="chart">
    <v-row align="center" justify="center">
      <v-col>
        <v-progress-linear v-if="salesOrderTotalsByReps.loading" indeterminate />
        <v-card align="center" justify="center">
          <v-card-text>
            <apexchart
              type="pie"
              :height="350"
              :width="salesOrderTotalsByReps.chartWidth"
              :options="salesOrderTotalsByReps.options"
              :series="salesOrderTotalsByReps.series"
            >
            </apexchart>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-progress-linear v-if="salesOrderTotalsByCreateUser.loading" indeterminate />
        <v-card>
          <v-card-text>
            <apexchart
              :height="350"
              type="pie"
              :width="salesOrderTotalsByCreateUser.chartWidth"
              :options="salesOrderTotalsByCreateUser.options"
              :series="salesOrderTotalsByCreateUser.series"
              background="#F5F5F5"
            >
            </apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col>
        <v-progress-linear v-if="salesOrderTotalsByWeek.loading" indeterminate />
        <v-card>
          <v-card-text>
            <apexchart
              type="line"
              :height="500"
              :width="salesOrderTotalsByWeek.chartWidth"
              :options="salesOrderTotalsByWeek.options"
              :series="salesOrderTotalsByWeek.series"
            >
            </apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col>
        <v-progress-linear v-if="salesOrderTotalsByGroup.loading" indeterminate />
        <v-card>
          <v-card-text>
            <apexchart
              type="bar"
              :height="500"
              :width="salesOrderTotalsByGroup.chartWidth"
              :options="salesOrderTotalsByGroup.options"
              :series="salesOrderTotalsByGroup.series"
            >
            </apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts" src="./sales-orders-dashboard.component.ts"></script>
<style>
.apexcharts-legend {
  align-content: flex-start !important;
  text-align: left !important;
  font-size: 20px !important;
}
</style>
